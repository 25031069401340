export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCEDDED = 'GET_USER_DETAILS_SUCCEDDED';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const getUserAction = () => ({
  type: GET_USER_DETAILS,
});

export const setUserAction = (payload) => ({
  type: GET_USER_DETAILS_SUCCEDDED,
  payload,
});

export const setUserProfileAction = (payload) => ({
  type: SET_USER_PROFILE,
  payload,
})
