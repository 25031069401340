import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const AlertMessage = props => {
  return (
    <Alert
      variant="filled"
      severity={props.severity}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            props.closeToast();
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {props.title ? <AlertTitle>{props.title}</AlertTitle> : null}
      {props.message}
    </Alert>
  );
};

export default AlertMessage;
