import axios from 'axios';

import { buildOauthPath, buildPath } from './base';
import {
  API_CLIENT_SECRET,
  API_PUBLIC_TOKEN_GRANT_TYPE,
  API_CLIENT_ID,
  API_PUBLIC_TOKEN_SCOPE,
  API_REFRESH_TOKEN_GRANT_TYPE,
} from './api';
import { dataURLtoFile } from '../helpers/base64ToImage';

export function getPublicToken() {
  const url = buildOauthPath('/oauth/token');
  const dataObj = {
    client_secret: API_CLIENT_SECRET,
    grant_type: API_PUBLIC_TOKEN_GRANT_TYPE,
    client_id: API_CLIENT_ID,
    scope: API_PUBLIC_TOKEN_SCOPE,
  };
  return axios.post(url, dataObj);
}

export function refreshToken(refreshToken) {
  const url = buildOauthPath('/oauth/token');
  const dataObj = {
    client_secret: API_CLIENT_SECRET,
    grant_type: API_REFRESH_TOKEN_GRANT_TYPE,
    client_id: API_CLIENT_ID,
    scope: API_PUBLIC_TOKEN_SCOPE,
    refresh_token: refreshToken,
  };
  return axios.post(url, dataObj);
}

export function* login(data) {
  const url = buildPath('/login');
  const dataObj = {
    ...data,
    client_id: API_CLIENT_ID,
    client_secret: API_CLIENT_SECRET,
  };

  return yield axios.post(url, dataObj);
}

export function* register(data) {
  const url = buildPath('/register');
  const dataObj = {
    ...data,
    client_id: API_CLIENT_ID,
    client_secret: API_CLIENT_SECRET,
  };

  return yield axios.post(url, dataObj);
}

export function* forgotPassword(data) {
  const url = buildPath('/password/forgot');
  const dataObj = {
    ...data,
  };

  return yield axios.post(url, dataObj);
}

export function* confirmAccount(data) {
  const url = buildPath(`/account-confirm/${data}`);

  return yield axios.get(url);
}

export function* resetPasswordTokenValidation(data) {
  const url = buildPath(`/password/reset/${data}`);

  return yield axios.get(url);
}

export function* resetPassword(data) {
  const url = buildPath('/password/reset');
  const dataObj = {
    ...data,
  };

  return yield axios.post(url, dataObj);
}

export function socialLogin(action) {
  const url = buildPath('/login/' + action.payload.provider);
  const dataObj = {
    social_access_token: action.payload.accessToken,
    client_id: API_CLIENT_ID,
    client_secret: API_CLIENT_SECRET,
  };
  return axios.post(url, dataObj);
}

export function getUserDetails() {
  const url = buildPath('/me');
  return axios.get(url);
}

export function logout() {
  const url = buildPath('/logout');
  return axios.post(url);
}

export function resetAccountPassword(data) {
  const url = buildPath('/change-password');
  return axios.post(url, data);
}
export function uploadAvatar(data) {
  const image = dataURLtoFile(data.image, 'avatar.jpg');

  const formData = new FormData();
  formData.append('image', image);
  const url = buildPath('/upload-avatar');

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function* saveRoleDobApi(data) {
  const url = buildPath('/update-role');
  return yield axios.post(url, data);
}

export function* getTimezone() {
  const url = buildPath('/timezone');
  return yield axios.get(url);
}
