// toastify
export const errorOptions = {
  progressClassName: 'toast-progress-bar',
  className: 'toast-container-error',
};

export const successOptions = {
  progressClassName: 'toast-progress-bar',
  className: 'toast-container-success',
};

export const USER_ROLES = {
  student: 'student',
  tutor: 'tutor',
  default: 'default',
};

export const RECURRENCE = [
  {
    value: 'never',
    name: 'Never',
  },
  {
    value: 'daily',
    name: 'Daily',
  },
  {
    value: 'dailyWorkdays',
    name: 'Daily only workdays',
  },
  {
    value: 'weekly',
    name: 'Weekly',
  },
  {
    value: 'monthly',
    name: 'Monthly',
  },
];

export const NOTIFICATIONS = [
  {
    name: '5 minute before class',
    value: 5,
  },
  {
    name: '30 minutes before class',
    value: 30,
  },
  {
    name: '1 hour before class',
    value: 60,
  },
  {
    name: '2 hours before class',
    value: 120,
  },
];

export const SEXES = [
  {
    name: 'Select',
    value: 0,
  },
  {
    name: 'Male',
    value: 1,
  },
  {
    name: 'Female',
    value: 2,
  },
  {
    name: 'Not specified',
    value: 9,
  },
];

export const HOURLY_RATE_FILTER = {
  '0-10': '0-10 $',
  '10-20': '10-20 $',
  '20-30': '20-30 $',
  '30-40': '30-40 $',
  '40-50': '40-50 $',
  '50-999': '+50 $',
};

export const YEARS_OF_EXPERIENCE_FILTER = {
  '0-1': 'less than 1 year',
  '1-2': '1-2 years',
  '2-3': '2-3 years',
  '3-4': '3-4 years',
  '4-5': '4-5 years',
  '5-99': '+5 years',
};

export const SORT_OPTIONS = [
  {
    name: 'Relevance',
    value: 'name',
  },
  {
    name: 'Years of experience',
    value: 'years_of_experience',
  },
  {
    name: 'Rating',
    value: 'rating',
  },
  {
    name: 'Hourly Rate',
    value: 'hourly_rate',
  },
];

export const SORT_OPTIONS_STUDENTS = [
  {
    name: 'Relevance',
    value: 'name',
  },
  {
    name: 'Newest',
    value: 'newest',
  },
];

export const CANCELLATION_POLICY_OPTIONS = [
  {
    name: '48 hours',
    value: 48,
  },
  {
    name: '24 hours',
    value: 24,
  },
  {
    name: '12 hours',
    value: 12,
  },
  {
    name: '6 hours',
    value: 6,
  },
  {
    name: '3 hours',
    value: 3,
  },
  {
    name: '2 hours',
    value: 2,
  },
  {
    name: '1 hour',
    value: 1,
  },
  {
    name: 'None',
    value: 0,
  },
]

export const CLASS_SCHEDULE_DURATION = [
  {
    name: '30 minutes',
    value: 30,
  },
  {
    name: '1 hour',
    value: 60,
  },
  {
    name: '1.5 hours',
    value: 90,
  },
  {
    name: '2 hours',
    value: 120,
  },
  {
    name: '3 hours',
    value: 180,
  },
  {
    name: '5 hours',
    value: 300,
  },
]
