import React from 'react';
import axios from 'axios';
import { takeLatest, call, select, put } from 'redux-saga/effects';
import moment from 'moment';
import { toast } from 'react-toastify';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

import * as Authentication from '../api/authentication';
import { setLoaderAction } from '../actions/loader';
import { getUserAction } from '../actions/user';
import { APPLICATION_INIT } from '../actions/application';
import AlertMessage from '../../common/components/alert/alert';
import { errorOptions } from '../constants';

export function isTokenValid(expirationDate) {
  if (
    expirationDate === undefined ||
    moment().isAfter(moment(expirationDate, 'x').format())
  ) {
    return false;
  }
  return true;
}

export function* generateToken(refreshToken) {
  let expirationDate;
  let accessToken;
  if (refreshToken) {
    try {
      const refreshTokenResponse = yield Authentication.refreshToken(
        refreshToken
      );
      expirationDate = refreshTokenResponse.data.expires_in;
      accessToken = refreshTokenResponse.data.access_token;
      refreshToken = refreshTokenResponse.data.refresh_token;
    } catch (err) {
      setCookie(null, 'refresh_token', "", {
        maxAge: 90000000,
        sameSite: 'lax',
        path: '/',
      });
      yield destroyCookie(null, 'refresh_token');
      return yield call(generateToken, null);
    }

    setCookie(null, 'refresh_token', refreshToken, {
      maxAge: 90000000,
      sameSite: 'lax',
      path: '/',
    });
  } else {
    try {
      const response = yield Authentication.getPublicToken();
      expirationDate = response.data.expires_in;
      accessToken = response.data.access_token;
    } catch (err) {
      toast(
        <AlertMessage severity="error" title="Error" message={err.message} />,
        errorOptions
      );
    }
  }
  setCookie(null, 'access_token', accessToken, {
    maxAge: 90000000,
    sameSite: 'lax',
    path: '/',
  });
  setCookie(
    null,
    'expires_in',
    moment().add(expirationDate, 'seconds').format('x'),
    {
      maxAge: 90000000,
      sameSite: 'lax',
      path: '/',
    }
  );
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

function* applicationInit() {
  const cookies = parseCookies();
  const accessToken = cookies.access_token;
  const expirationDate = cookies.expires_in;
  const refreshToken = cookies.refresh_token;
  if (!accessToken || !expirationDate || !isTokenValid(expirationDate)) {
    yield call(generateToken, refreshToken);
  }
  axios.defaults.headers.common['accept-language'] = cookies['next-i18next'];
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axios.defaults.headers.common['Cache-Control'] = 'no-store, must-revalidate';
  axios.defaults.headers.common.Pragma = 'no-cache';
  axios.defaults.headers.Expires = 0;
  if (refreshToken) {
    yield put(getUserAction());
  }
  yield put(
    setLoaderAction({ value: false, componentSpinner: 'generalLoader' })
  );
}

// function* handleError(err, saga, action, ...args) {
//     if (err.response && [400, 401, 403, 404, 405, 406, 422, 500].includes(err.response.status)) {
//         if (err.response.status === 403) {
//                 const cookies = parseCookies();
//                 let refreshToken = cookies.refresh_token;
//                 yield call(generateToken, refreshToken);
//         }
//         yield put(showAlert({
//             type: ALERT_TYPES.error,
//             message: err.response.data.message,
//         }));
//         yield takeADump(err);
//     } else {
//         yield put(showAlert({
//             type: ALERT_TYPES.error,
//             message: err.response.data.message,
//         }));
//         yield takeADump(err);
//     }
// }
//
// export const genericErrorHandler = (saga, ...args) => (
//     function* handleApp(action) {
//         try {
//             yield call(saga, ...args, action);
//         } catch (err) {
//             console.log(err);
//             yield call(handleError, err, saga, action, ...args);
//         }
//     }
// );

export function* applicationSaga() {
  yield takeLatest(APPLICATION_INIT, applicationInit);
}
